<div class="mc-control-bar">
	<ul class="mc-control-bar__items">
		<li class="mc-control-bar__item mc-control-bar__item--reset"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="left"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngClass]="{'active': resetPopover?.isOpen(), 'disabled': !resetStatus }"
			[ngbPopover]="!isMobile ? ('control_panel.reset' | translate) : null">
			<div class="mc-control-bar__item-inside"
				 #resetPopover="ngbPopover"
				 container="body"
				 [style.display]="resetStatus ? 'block' : 'none'"
				 (click)="$event.stopPropagation()"
				 [placement]="resetPopoverPosition"
				 [ngbPopover]="resetIVScreen"
				 popoverClass="mc-popover mc-popover-reset"></div>
		</li>
		<li class="mc-control-bar__item mc-control-bar__item--shutter"
			(click)="onShutterControl()"
			[ngClass]="{'active': shutterControlStatus, 'mobile': isMobile}"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="left"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('control_panel.shutter' | translate) : null"></li>
		<li class="mc-control-bar__item mc-control-bar__item--filters"
			(click)="onSelectControl('blinds')"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="left"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('control_panel.filters' | translate) : null"></li>
		<li class="mc-control-bar__item mc-control-bar__item--background"
			(click)="onBackgroundSelectControl($event)"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="left"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('control_panel.background' | translate) : null"></li>
		<li class="mc-control-bar__item mc-control-bar__item--save"
			(click)="onSaveControl($event)"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="left"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('control_panel.save' | translate) : null"></li>
		<li class="mc-control-bar__item mc-control-bar__item--request"
			(click)="onRequestControl()"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="left"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('control_panel.overview' | translate) : null"></li>
	</ul>

	<div class="mc-control-bar__controls" [class]="{'open': isOpenControls}">
		<div class="mc-control-bar__controls-header"
			 [class]="{'center': controlType === 'blinds'}">
			<div class="mc-control-bar__controls-title">
				{{ 'control_panel.titles.' + controlType | translate }}
			</div>
			<div class="mc-control-bar__controls-close" (click)="onClose()"></div>
		</div>
		<app-features-control
				class="mc-control-bar__control mc-blind__control"
				[class]="{'open': controlType === 'blinds'}"
				*ngIf="!isDesignType"></app-features-control>
	</div>

	<ng-template #resetIVScreen>
		<div class="mc-control-bar__reset-title">{{ 'modals.reset_IV.title' | translate }}</div>
		<button class="mc-control-bar__button yes" (click)="onResetIVScreen()">
			{{ 'modals.reset_IV.buttons.yes' | translate }}
		</button>
		<button class="mc-control-bar__button no" (click)="[resetPopover.close(), $event.stopPropagation()]">
			{{ 'modals.reset_IV.buttons.no' | translate }}
		</button>
	</ng-template>
</div>
