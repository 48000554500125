import {
	Component,
	OnDestroy,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
	HostListener,
	AfterViewInit
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ActivatedRoute } from '@angular/router';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AccordionSelector, BlindData, Breakpoints } from '@root/app.interfaces';
import { STORAGE_NAMES, SELECTORS, VIEW_TYPES, BREAKPOINTS, CONFIG } from '@root/app.config';
import { PassDataService } from '@core/services/pass-data/pass-data.service';

declare var $: any;
import * as _ from 'lodash';

@Component({
	selector: 'app-blind-list',
	templateUrl: './blind-list.component.html',
	styleUrls: ['./blind-list.component.scss', './blind-list.responsive.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlindListComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('newBlindName') newBlindName: ElementRef;
	@ViewChild('blindListContainer') blindListContainer: ElementRef<HTMLElement>;

	isMobile: boolean;
	blindsData: BlindData[];
	accordShow: boolean;
	item = {
		length: 0,
		id: 0
	};
	slideSpeed = 200;
	isBlindName = true;
	isModelLoaded: string;
	isFirstLoading = true;
	blindTempName: string;
	sessionText = {
		material_title: '',
		operation_title: '',
		mounting_title: '',
		size_pop_up_text: ''
	};
	blindSelectors: AccordionSelector = {
		title: '.mc-blind-item',
		content: '.mc-blind-item__setup-container',
		item: '.mc-blind-item'
	};
	blindId: number;
	blindItemObject = {};
	blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
	blindListOpened: boolean;
	mobileSelectedBlindId: number;
	isDesignType: boolean;
	isBlindSelected = true;
	setBlindSelectData = {
		id: 0,
		type: ''
	};

	breakpoints: Breakpoints = BREAKPOINTS;
	swipeCoordY: number;

	getBlindsData: Subscription;
	getBlindStatus: Subscription;
	getSessionBlindsData: Subscription;
	getActivatedRouteRules: Subscription;
	getScreenShot: Subscription;
	getModelLoadedStatus: Subscription;
	getBlindsUpdated: Subscription;
	getBlindType: Subscription;
	getViewType: Subscription;

	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange() {
		const isLandscape = screen.orientation.type.includes('landscape');
		const width = [window.innerWidth, window.innerHeight].sort((a, b) => a - b)[+isLandscape];

		if (this.isWidthLessThanTabletLandscape(width)) {
			setTimeout(this.callBlindListSwipeAnimation.bind(this, this.blindListOpened, false, 0));
		}
	}

	activeBlindItem(id: number): void {
		this.blindId = id;

		this.collapseAccordionList();
		if (this.blindListOpened || window.innerWidth > CONFIG.breakpoints['tablet-landscape']) {
			this.driveAccordionItem(id, true);
		}

		this.blindNameSave(id);

		if (this.isWidthLessThanTabletLandscape() && !this.blindListOpened) {
			this.scrollToCurrentBlind();
		}

	}

	constructor(
		public shareDataService: ShareService,
		public engineService: EngineService,
		public serverDataService: ServerDataService,
		public saveSessionBlind: SaveSessionBlindsService,
		public route: ActivatedRoute,
		public screenShotService: ScreenshotService,
		public sessionStorageService: SessionStorageService,
		public passDataService: PassDataService,
		public changeDetection: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {
		this.isMobile = this.passDataService.isMobileOrTablet;
		this.isDesignType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
		this.getActivatedRouteRules = this.route.data.subscribe(res => {
			this.getSessionDefaultValue();
			this.getSessionStorageData();
			this.setSessionStorage();

			setTimeout(this.setNameMask.bind(this));

			this.changeDetection.markForCheck();
		});

		this.getServerDataHandler();
		this.getBlindsData = this.shareDataService.currentData.subscribe(this.setBlindDataHandler.bind(this));
		this.getBlindStatus = this.shareDataService.getStatus.subscribe(status => {
			this.isBlindName = status;
			this.changeDetection.markForCheck();
		});

		this.getScreenShot = this.screenShotService.getScreenShot.subscribe(this.setScreenShotToBLind.bind(this));
		this.getModelLoadedStatus = this.shareDataService.getSceneCreated.subscribe(res => {
			this.isModelLoaded = res;

			if (this.isFirstLoading) {
				this.accordionCurrentBlind();
				this.isFirstLoading = false;
			}

			if (!this.isFirstLoading && !this.isBlindSelected && this.setBlindSelectData.type === res) {
				this.isBlindSelected = true;
				this.shareDataService.setBlindSelect(this.setBlindSelectData);
			}
		});

		this.getBlindsUpdated = this.shareDataService.getBlindsUpdated.subscribe(() => {
			if (this.blindsData.length === 0) {
				this.deletedLastBlindItem();
			}
		});

		this.getViewType = this.shareDataService.getViewType.subscribe((res) => {
			if (res !== VIEW_TYPES.design && this.isScreenShotLoadedOnServer) {
				this.accordionCurrentBlind();
			}
		});
	}

	ngOnDestroy(): void {
		this.getBlindsData.unsubscribe();
		this.getBlindStatus.unsubscribe();
		this.getBlindsUpdated.unsubscribe();
		this.getActivatedRouteRules.unsubscribe();
		this.getScreenShot.unsubscribe();
		this.getModelLoadedStatus.unsubscribe();
		this.getSessionBlindsData?.unsubscribe();
		this.getViewType.unsubscribe();
	}

	ngAfterViewInit() {
		this.mobileSelectedBlindId = this.mobileSelectedBlindId || this.getBlindIdFromStorage();
	}

	getServerDataHandler(): void {
		this.getSessionBlindsData = this.shareDataService.getSessionBlindsData.subscribe(res => {
			this.setConfigurationDataHandler();

			if (!res && this.isModelLoaded.length) {
				this.engineService.createScene(this.isModelLoaded);
			}

			this.changeDetection.markForCheck();
		});

		this.setConfigurationDataHandler();
	}

	setConfigurationDataHandler(): void {
		this.getSessionDefaultValue();
		this.getSessionStorageData();

		if (this.blindsData) {
			setTimeout(this.accordionCurrentBlind.bind(this));
		}

		setTimeout(this.setNameMask.bind(this));
	}

	setSessionStorage(): void {
		const sessionStorageSession = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);

		if (sessionStorageSession) {
			this.shareDataService.setLinkSessionKey(sessionStorageSession);
		}
	}

	getSessionStorageData(): void {
		const storageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);

		if (_.isEmpty(this.blindsData)) {
			this.blindsData = storageData;
		} else {
			storageData.forEach((blind, index) => {
				this.blindsData[index] = Object.assign(this.blindsData[index] || {}, blind);
			});
		}
	}

	setBlindDataHandler(data: BlindData[]): void {
		for (const item of data) {
			this.item = {
				length: data.length,
				id: item.blind_id
			};
		}
		setTimeout(() => {
			this.setNameMask();
			this.activeBlindItem(this.item.id);
			this.sessionStorageService.setBlindData(true, STORAGE_NAMES.zip_model_init);
		});

		this.blindTempName = '';
		this.getSessionStorageData();

		if (this.blindsData.length > 0) {
			this.shareDataService.setStatus(false);
		}
		this.blindItemObject = {};
		this.changeDetection.markForCheck();
	}

	blindName(newBlindName, event, id): void {
		this.blindTempName = newBlindName.trim();
		
		if (event.keyCode === 13 || event.type === 'blur') {
			this.blindNameSave(id);
		}
	}

	setNameMask(): void {
		const blindList = $(SELECTORS.blind_list);

		if (!_.isEmpty(this.blindsData)) {
			for (const item of this.blindsData) {

				if (item.has_name) {
					for (const blindId of blindList) {
						const currentBlindId = $(blindId).find(`#${ item.blind_id }`);
						currentBlindId.find(SELECTORS.config_item_setup).addClass('named');
					}
				}
			}
		}
	}

	blindNameSave(id): void {
		const blindList = $(SELECTORS.blind_list);

		for (const blindId of blindList) {
			const currentBlindId = $(blindId).find(`#${ id }`);

			if (this.newBlindName?.nativeElement) {
				this.blindTempName = $(this.newBlindName.nativeElement).val();
			}

			if (this.blindTempName?.length > 1) {
				currentBlindId.find(SELECTORS.config_item_setup).removeClass('error');
				currentBlindId.find(SELECTORS.config_item_setup).addClass('named');

				this.getBlindData(id, true);
				this.shareDataService.setStatus(true);
			} else {
				currentBlindId.find(SELECTORS.config_item_setup).addClass('error');
			}
		}
	}

	blindNameEdit(id, event): void {
		const elem = $(event.currentTarget);
		$(event.currentTarget).closest(SELECTORS.config_item).removeClass('named');
		$(event.currentTarget).closest(SELECTORS.config_item).removeClass('error');
		setTimeout(() => elem.children().focus());

		this.getBlindData(id, false);
		this.shareDataService.setStatus(false);
	}

	getBlindData(id, hasName): void {
		const storage = this.isDesignType ? STORAGE_NAMES.zip_blind_data : STORAGE_NAMES.zip_blind_data_iv;
		const blindItem = this.blindsData.filter(x => x.blind_id === id)[0];
		const sessionStorageData = this.sessionStorageService.getBlindData(storage);
		const blindItemCopy = sessionStorageData.filter(x => x.blind_id === id)[0];

		blindItem.setup = blindItemCopy.setup;

		if (this.blindTempName?.length && (_.isEmpty(blindItem.name) || !blindItem.has_name)) {
			blindItem.name = this.blindTempName;
		} else {
			this.blindTempName = blindItem.name;
		}

		blindItem.has_name = hasName;

		sessionStorageData[sessionStorageData.indexOf(blindItemCopy)] = blindItem;

		this.saveBlindData(sessionStorageData);

		if (this.isDesignType) {
			this.shareDataService.setBlindName(this.blindTempName);
		}
	}

	getCountryCode(): any {
		return this.sessionStorageService.getSession(STORAGE_NAMES.zip_country_code);
	}

	setScreenShotToBLind(res): void {
		if (!this.isDesignType) {
			return;
		}

		const tempSessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_temp_session);
		const sessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);

		const imageName = !_.isEmpty(tempSessionKey) ? `${ tempSessionKey }_${ res.blindId }` : `${ sessionKey }_${ res.blindId }`;

		const imageData = {
			country: this.getCountryCode(),
			image: res.img,
			name: imageName
		};

		this.isScreenShotLoadedOnServer = false;
		
		this.serverDataService.PostImageData(imageData).subscribe(() => {
			const blindData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
			const blindItem = blindData.filter(x => x.blind_id === res.blindId);
			const currentBlindsDataItem = this.blindsData.filter(x => x.blind_id === res.blindId);
			this.isScreenShotLoadedOnServer = true;

			blindItem.map(blindObj => {
				blindObj.blind_img = imageName + '.png';
			});

			currentBlindsDataItem.map(blindObj => {
				blindObj.blind_img = imageName + '.png';
			});

			this.sessionStorageService.setBlindData(blindData, STORAGE_NAMES.zip_blind_data);
			this.shareDataService.setScreenShotLoadedOnServer(this.isScreenShotLoadedOnServer);
		});

		this.changeDetection.markForCheck();
	}

	accordionConfAction(event: Event, selector: string, status: boolean): void {
		const accordionList = $(selector);
		const accordionItem = $(event.currentTarget).parents(selector);

		for (const item of accordionList) {
			if ($(item).hasClass('active')) {
				$(item).children(0).next().stop().slideUp(this.slideSpeed, () => {
					$(item).removeClass('active');
				});
			}
		}

		this.accordShow = status;
		this.accordShow = !this.accordShow;

		if (accordionItem.hasClass('active')) {
			$(event.currentTarget).next().stop().slideUp(this.slideSpeed, () => {
				this.accordShow = false;
				accordionItem.removeClass('active');
			});
		} else {
			accordionItem.addClass('active');
			$(event.currentTarget).next().stop().height('auto').slideDown(this.slideSpeed, () => {
				this.accordShow = true;
			});
		}
	}

	collapseAccordionList(): void {
		const accordionList = this.blindListContainer?.nativeElement.children || [];
		[...accordionList]?.forEach((item: HTMLElement) => {
			if (item.id) {
				item.dataset.slided = 'false';
				item.classList.remove('active');
				item.querySelector(this.blindSelectors.content).classList.add('hidden');
			}
		});
	}

	driveAccordionItem(id: number, open: boolean): void {
		const accordionList = this.blindListContainer?.nativeElement.children || [];
		[...accordionList]?.forEach((item: HTMLElement) => {
			if (item.id === id.toString()) {
				item.classList.toggle('active', open);
				item.dataset.slided = open.toString();
				item.querySelector(this.blindSelectors.content).classList.toggle('hidden', !open);
			}
		});

		this.mobileSelectedBlindId = id;

		setTimeout(this.scrollToCurrentBlind.bind(this), 100);
	}

	accordionBlindItems(event: Event, itemId: number, type: string): void {
		if (this.isBlindName) {
			let slided = false;
			let active = false;
			let mobileSelected = false;

			if (event) {
				const element = event.currentTarget as HTMLElement;
				const accordionItem = element.parentElement;

				slided = accordionItem.dataset.slided === 'true';
				active = accordionItem.classList.contains('active');
				mobileSelected = accordionItem.classList.contains('mobile-selected');
			}

			if (!this.blindListOpened && this.isWidthLessThanTabletLandscape() && mobileSelected) {
				this.callBlindListSwipeAnimation(true);
			}

			if (event && (slided || active)) {
				this.driveAccordionItem(itemId, false);
				this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_current_blind_id);
				this.sessionStorageService.setBlindData(itemId, STORAGE_NAMES.zip_last_opened_blind_id);
			} else {
				const screenshotPromise = new Promise((resolve, reject) => {
					if (!this.isFirstLoading) {
						this.saveCurrentBlindScreenshot();
					}
					resolve(true);
				});
				this.sessionStorageService.setBlindData(itemId, STORAGE_NAMES.zip_current_blind_id);
				this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_last_opened_blind_id);

				screenshotPromise.then(() => {
					if (this.isModelLoaded !== type) {
						this.engineService.createScene(type);
						this.sessionStorageService.setSession(type, STORAGE_NAMES.zip_blind_type);
					} else {
						this.shareDataService.setAccordionType(type);
					}
				});

				this.collapseAccordionList();
				setTimeout(this.driveAccordionItem.bind(this, itemId, true));
				this.shareDataService.setCurrentBlindByType(type);

				this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data)
					.filter(x => x.blind_id === itemId).map((item: BlindData) => {
					this.setBlindSelectData = {
						id: item.blind_id,
						type: item.type
					};

					if (item.has_name) {
						this.shareDataService.setBlindName(item.name);

						if (this.isModelLoaded === type) {
							this.shareDataService.setBlindSelect(this.setBlindSelectData);
						}

						this.isBlindSelected = this.isModelLoaded === type;
					}
				});

				if (this.blindListOpened && this.isWidthLessThanTabletLandscape()) {
					setTimeout(this.scrollToCurrentBlind.bind(this, 'smooth'));
				}
			}
		}
	}

	deleteBlindItem(itemId: number): void {
		const sessionSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		this.getSessionStorageData();

		const deletedBlindIndex = this.blindsData.findIndex(x => x.blind_id === itemId);
		if (deletedBlindIndex < this.blindsData.length - 1) {
			this.blindsData.forEach((x, i) => x.name = i > deletedBlindIndex && x.name === `Blind ${ i + 1 }` ? `Blind ${ i }` : x.name);
		}

		this.blindsData.splice(deletedBlindIndex, 1);
		this.saveBlindData();

		if (this.blindsData.length > 0) {
			this.engineService.setModelDefaultPosition();
			this.shareDataService.setBlindUnselect(true);
			this.accordionCurrentBlind(true);
			this.scrollToCurrentBlind();
		} else if (!sessionSaved) {
			this.deletedLastBlindItem();
		} else {
			document.querySelector(SELECTORS.tool_bar_accordion).classList.add('loading');
		}

		this.shareDataService.setStatus(true);
		this.blindItemObject = {};
	}

	openPopover(e, popover): void {
		e.stopPropagation();
		
		if (this.isBlindName) {
			popover.open();
			this.saveCurrentBlindScreenshot();
		}
	}

	closePopover(e, popover): void {
		e.stopPropagation();
		popover.close();
	}

	getSessionDefaultValue(): void {
		const sessionStorageText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);

		if (!_.isEmpty(sessionStorageText)) {
			this.sessionText = sessionStorageText;
		}
	}

	saveCurrentBlindScreenshot(): void {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		this.screenShotService.saveScreenShot(currentBlindId, false);
	}

	accordionCurrentBlind(afterDelete?: boolean): void {
		if (!_.isEmpty(this.blindsData) && this.isModelLoaded) {
			const lastOpenedBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id) ||
				this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
			const lastArrayBlind = this.blindsData[this.blindsData.length - 1];
			const lastOpenedBlind =
				this.sessionStorageService.getBlindItemById(lastOpenedBlindId, STORAGE_NAMES.zip_blind_data)[0] || lastArrayBlind;
			const lastOpenedBlindElement = $(`#${ lastOpenedBlind.blind_id }`);

			if (!lastOpenedBlindElement[0].classList.contains('active') || afterDelete) {
				this.accordionBlindItems(null, lastOpenedBlind.blind_id, lastOpenedBlind.type);
			}
		}
	}

	onBlindItemDescriptionHandler(event: Event, id?: number) {
		const currentBlind = this.blindsData.filter(item => item.blind_id === id)[0];
		this.blindItemObject = { ...currentBlind.setup, ...event };
		currentBlind.setup = this.blindItemObject;
	}

	deletedLastBlindItem(): void {
		this.shareDataService.setBlindEmpty(true);
		this.engineService.createScene(this.isModelLoaded);
		this.engineService.setModelDefaultPosition();
	}

	onPopover(popover: NgbPopover, button: HTMLElement): void {
		$(button).toggleClass('active', popover.isOpen());
	}

	saveBlindData(data: any = this.blindsData): void {
		const storage = this.isDesignType ? STORAGE_NAMES.zip_blind_data : STORAGE_NAMES.zip_blind_data_iv;

		this.sessionStorageService.setBlindData(data, storage);

		if (this.isDesignType) {
			this.saveSessionBlind.PutStorageDataToServer();
		} else {
			this.shareDataService.setIVDataChanged(true);
		}
	}

	onTouchEvent(event: TouchEvent): void {
		const blindList = this.blindListContainer?.nativeElement as HTMLElement;
		const isMobileSliderTouch = (event.target as HTMLElement).closest(SELECTORS.mobile_slider);
		const isBlindItemTouch = (event.target as HTMLElement).closest(SELECTORS.blind_item);
		const isBlindListTouch = (event.target as HTMLElement).closest(SELECTORS.blind_list);
		const isBlindsListHasScroll = blindList.scrollHeight - 10 > blindList.clientHeight;
		const isBlindsListScrolled = blindList.scrollTop !== 0;
		const isDesktopScreen = window.innerWidth > CONFIG.breakpoints['tablet-landscape'];
		
		if ((this.blindListOpened && isBlindsListScrolled && isBlindListTouch) || isDesktopScreen) {
			return;
		}

		const maxOpenHeight = 120;
		const minCloseHeight = window.innerHeight - 200;
		const coordY = event.changedTouches[0].clientY;
		const direction = coordY - this.swipeCoordY;
		
		if (event.type === 'touchstart') {
			this.swipeCoordY = coordY;
		} else if (event.type === 'touchmove') {
			if ((isBlindListTouch && !isBlindsListHasScroll) || (!isBlindsListScrolled && direction >= 0) ||
				isMobileSliderTouch || !this.blindListOpened) {
				event.preventDefault();
			}

			this.swipeCoordY = coordY;
			blindList.style.height = blindList.offsetHeight - direction + 'px';

			const listStatus = blindList.offsetHeight > maxOpenHeight && direction < 0 && !this.blindListOpened ? true :
				blindList.offsetHeight < minCloseHeight && direction > 0 ? false : null;

			this.callBlindListSwipeAnimation(listStatus);
		} else if (event.type === 'touchend') {
			if (isBlindItemTouch && !this.blindListOpened) {
				return;
			}

			const listStatus = blindList.offsetHeight < maxOpenHeight ? false :
				blindList.offsetHeight > minCloseHeight ? true : null;

			this.callBlindListSwipeAnimation(listStatus, false);
		}
	}
	
	onPanel(event: Event) {
		const arrowElement = event.currentTarget as HTMLElement;
		this.callBlindListSwipeAnimation(!arrowElement.classList.contains('opened'));
	}
	
	callBlindListSwipeAnimation(listStatus, itemStatus = true, duration = 500) {
		if (listStatus === null) {
			return;
		}

		const isNotScrollToBLind = this.blindListOpened && listStatus;
		this.blindListOpened = listStatus;
		const blindList = this.blindListContainer?.nativeElement;

		if (blindList) {
			if (blindList.getAnimations().length > 0) {
				return;
			}

			const animationOptions = { duration, iterations: 1, fill: 'forwards' };
			const animationKeyframes = [
				{ height: blindList.offsetHeight + 'px' },
				{ height: listStatus ? '100vh' : '0px' },
			];

			// @ts-ignore
			const animation = this.blindListContainer.nativeElement.animate(animationKeyframes, animationOptions);
			animation.onfinish = () => {
				blindList.style.height = (listStatus ? window.innerHeight : 0) + 'px';
				animation.cancel();

				if (listStatus && itemStatus) {
					this.driveAccordionItem(this.mobileSelectedBlindId, true);
				} else if (itemStatus) {
					this.collapseAccordionList();
				}

				if (!isNotScrollToBLind) {
					this.scrollToCurrentBlind();
				}
			};
		}
	}

	scrollToCurrentBlind(behavior: ScrollBehavior = this.isWidthLessThanTabletLandscape() ? 'auto' : 'smooth') {
		this.mobileSelectedBlindId = this.getBlindIdFromStorage();

		const selectedBlind = document.getElementById(this.mobileSelectedBlindId?.toString());
		selectedBlind?.scrollIntoView({ behavior });

		if (this.isWidthLessThanTabletLandscape() && !this.blindListOpened) {
			setTimeout(this.collapseAccordionList.bind(this));
		}
	}

	get isScreenShotLoadedOnServer(): boolean {
		return this.passDataService.isScreenShotLoadedOnServer;
	}

	set isScreenShotLoadedOnServer(status: boolean) {
		this.passDataService.isScreenShotLoadedOnServer = status;
	}

	getBlindIdFromStorage(): number | undefined {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const lastOpenedBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);

		return currentBlindId || lastOpenedBlindId;
	}

	isWidthLessThanTabletLandscape(width: number = window.innerWidth): boolean {
		return width <= CONFIG.breakpoints['tablet-landscape'];
	}


	copyBlindItem(id: number): void {
		let copiedBlind: BlindData;
		const copyWord = 'Copy';
		const storageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const currentBlind = storageData.filter(blindArray => blindArray.blind_id === id)[0];
		const copiedBlindIndex = storageData.indexOf(currentBlind) + 1;
		const nameWithoutCopy = (name: string) => name.split(copyWord)[0].trim();
		const checkDuplicate = (name: string, currentName: string) => 
			name.includes(nameWithoutCopy(currentName)) && name.includes(copyWord);
		const copyCounter = storageData.filter((blind: BlindData) => checkDuplicate(blind.name, currentBlind.name)).length;

		if (currentBlind) {
			const copy = { ...currentBlind };

			copy.blind_id = Math.floor(1000 + Math.random() * 9000);
			copy.name = copyCounter ? `${nameWithoutCopy(copy.name)} ${copyWord} ${copyCounter}` :
				`${copy.name} ${copyWord}`;
			copiedBlind = copy;
		}

		storageData.splice(copiedBlindIndex, 0, copiedBlind);
		this.blindsData = storageData;

		this.saveBlindData(storageData);
		this.engineService.setModelDefaultPosition();
		setTimeout(this.accordionBlindItems.bind(this, null, copiedBlind.blind_id, copiedBlind.type));
	}
}
