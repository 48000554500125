import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';

declare var $: any;

@Injectable({
	providedIn: 'root'
})
export class ScreenshotService implements OnDestroy {
	getSessionStorageScreenshot: Subscription;
	screenShot = new Subject<any>();
	getScreenShot = this.screenShot.asObservable();
	
	constructor(
		private engineService: EngineService,
		private shareService: ShareService
	) {
	}
	
	ngOnDestroy(): void {
		this.getSessionStorageScreenshot.unsubscribe();
	}
	
	setScreenShot(status: any): void {
		this.screenShot.next(status);
	}
	
	trimPhoto(id) {
		this.getSessionStorageScreenshot = this.shareService.getScreenShotBLindTemp.subscribe(res => {
			const canvas = document.getElementById('modified') as HTMLCanvasElement;
			const tempImg = $('#tempImg');
			tempImg.attr('src', res);
			const ctx = canvas.getContext('2d');
			
			const promise = new Promise((resolve, reject) => {
				tempImg[0].onload = () => {
					const w = tempImg.width();
					const h = tempImg.height();
					const image = $('#tempImg')[0];
					canvas.height = h;
					canvas.width = w;
					ctx.drawImage(image, 0, 0, w, h, 0, 0, canvas.width, canvas.height);
					this.trimCanvas(canvas, w, h, id);
					resolve(true);
				};
			});
			promise.then(() => {
				this.shareService.setScreenShotSaved(true);
			});
		});
	}
	
	trimCanvas(c, w, h, id): any {
		const ctx = c.getContext('2d');
		const copy = document.createElement('canvas').getContext('2d');
		const pixels = ctx.getImageData(0, 0, w, h);
		const l = pixels.data.length;
		let i;
		const bound = {
			top: null,
			left: null,
			right: null,
			bottom: null
		};
		let x;
		let y;
		
		for (i = 0; i < l; i += 4) {
			if (pixels.data[i + 3] !== 0) {
				x = (i / 4) % w;
				
				// tslint:disable-next-line:no-bitwise
				y = ~~((i / 4) / w);
				
				if (bound.top === null) {
					bound.top = y;
				}
				
				if (bound.left === null) {
					bound.left = x;
				} else if (x < bound.left) {
					bound.left = x;
				}
				
				if (bound.right === null) {
					bound.right = x;
				} else if (bound.right < x) {
					bound.right = x;
				}
				
				if (bound.bottom === null) {
					bound.bottom = y;
				} else if (bound.bottom < y) {
					bound.bottom = y;
				}
			}
		}
		
		const trimHeight = bound.bottom - bound.top;
		const trimWidth = bound.right - bound.left;
		if (trimWidth > 0 || trimHeight > 0) {
			const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
			
			copy.canvas.width = trimWidth;
			copy.canvas.height = trimHeight;
			copy.putImageData(trimmed, 0, 0);
			
			this.setScreenShot({
				img: copy.canvas.toDataURL(),
				blindId: id
			});
		}
	}
	
	saveScreenShot(id, status): void {
		this.engineService.setScreenShot(status);
		this.trimPhoto(id);
	}
}
