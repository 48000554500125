<div class="mc-tool-bar">
	<div class="mc-tool-bar__controls" [ngClass]="status ? 'open' : 'close'">
		<div *ngIf="simpleBarStatus" data-simplebar data-simplebar-auto-hide="false" class="mc-tool-bar__simple-bar">
			<div #toolBarContainer class="mc-tool-bar-accordion">
				<span class="loader"></span>
				<app-blind-list></app-blind-list>
			</div>
		</div>
		<div *ngIf="!simpleBarStatus" class="mc-tool-bar__simple-bar">
			<div #mobileToolBarContainer class="mc-tool-bar-accordion">
				<span class="loader"></span>
				<app-blind-list></app-blind-list>
			</div>
		</div>
	</div>
</div>
