import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import { STORAGE_NAMES, SELECTORS } from '@root/app.config';

declare var $: any;
import * as _ from 'lodash';

@Component({
	selector: 'app-overview-page',
	templateUrl: './overview-page.component.html',
	styleUrls: ['./overview-page.component.scss', './overview-page.responsive.scss']
})
export class OverviewPageComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('retailerInfo') retailerInfo: ElementRef;
	@ViewChild('overviewPage') overviewPage: ElementRef;
	
	getRetailerInfo: Subscription;
	getLoadingSpinner: Subscription;
	getActivatedRouteRules: Subscription;
	
	addInfoAvailability = false;
	
	constructor(
		private shareService: ShareService,
		private sessionStorageService: SessionStorageService,
		private route: ActivatedRoute,
		private changeDetection: ChangeDetectorRef
	) {
	}
	
	ngOnInit(): void {
		this.getRetailerInfo = this.shareService.getRetailerInfo.subscribe(res => {
			if (this.addInfoAvailability) {
				$(SELECTORS.request_button).removeClass('loading');
				this.scrollToRetailerInfo();
			}
			
			const embeddedRetailer = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer);
			this.addInfoAvailability = res;
			
			if (this.addInfoAvailability && !embeddedRetailer) {
				this.scrollToRetailerInfo();
			}
			
			this.changeDetection.markForCheck();
		});
		
		this.getActivatedRouteRules = this.route.data.subscribe(() => {
			this.setSessionStorage();
			
			this.changeDetection.markForCheck();
		});
		
		this.setLoadingHandler();
	}
	
	scrollToRetailerInfo() {
		setTimeout(() => {
			this.retailerInfo.nativeElement.scrollIntoView({
				behavior: 'smooth'
			});
		}, 200);
	}
	
	ngAfterViewInit() {
		this.getLoadingSpinner = this.shareService.getLoadingSpinner.subscribe(res => {
			
			setTimeout(() => {
				$(this.overviewPage.nativeElement).find(SELECTORS.overview_section).toggleClass('loading', res);
			});
			
			this.changeDetection.markForCheck();
		});
	}
	
	setSessionStorage(): void {
		const sessionStorageSession = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);
		
		if (sessionStorageSession) {
			this.shareService.setLinkSessionKey(sessionStorageSession);
		}
	}
	
	setLoadingHandler(): void {
		const savedStatus = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		const savedData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		
		if (savedStatus && _.isEmpty(savedData)) {
			setTimeout(() => {
				$(this.overviewPage.nativeElement).find(SELECTORS.overview_section).addClass('loading');
			});
		}
	}
	
	setInfoAvailability(status): void {
		this.addInfoAvailability = status;
	}
	
	ngOnDestroy(): void {
		this.getRetailerInfo.unsubscribe();
		this.getActivatedRouteRules.unsubscribe();
	}

	getCurrentYear(): number {
		return new Date().getFullYear();
	}

}
