import { Injectable } from '@angular/core';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ShareService } from '../share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import * as _ from 'lodash';
import { IBlindSelect } from '@core/config';
import { SELECTORS, STORAGE_NAMES } from '@root/app.config';

@Injectable({
	providedIn: 'root'
})
export class SaveSessionBlindsService {
	isNewBlind: boolean | string;
	getBlindSelect = this.shareService.getBlindSelect.subscribe((res: IBlindSelect) => {
		this.isNewBlind = res.status === 'new';
	});
	
	constructor(
		private setupPageService: SetupPageService,
		private serverDataService: ServerDataService,
		private screenShotService: ScreenshotService,
		private sessionStorageService: SessionStorageService,
		private shareService: ShareService,
	) {
	}
	
	PutStorageDataToServer(resolve?: (value: boolean) => void): void {
		const sessionSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		
		if (sessionSaved) {
			const sessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);
			const getStorageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
			const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
			const embedKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embed_key);
			const lastBlindSetup = getStorageData[getStorageData.length - 1]?.setup;
			const toolBarAccordion = document.querySelector(SELECTORS.tool_bar_accordion);
			const storageDataWithAction = {
				country: this.setupPageService.getCountryCode(),
				embed_key: embedKey,
				action: 'blinds-update',
				blinds: getStorageData
			};
			
			if (lastBlindSetup?.mounting || _.isEmpty(getStorageData)) {
				if (this.isNewBlind) {
					toolBarAccordion.classList.add('loading');
					this.isNewBlind = false;
				}
				
				this.preventReloadPage(true);
				this.screenShotService.saveScreenShot(currentBlindId, true);
				this.serverDataService.PutSessionData(storageDataWithAction, sessionKey).subscribe(() => {
					toolBarAccordion?.classList.remove('loading');
					this.preventReloadPage(false);
					this.shareService.setBlindsUpdated(true);
					
					if (resolve) {
						resolve(true);
					}
				});
			}
		}
	}
	
	PutStorageDataToServerPromise() {
		return new Promise((resolve) => {
			this.PutStorageDataToServer(resolve);
		});
	}
	
	putSaveBlinds(email = '', resolve?: (value: boolean) => void): void {
		const getStorageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const embedKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embed_key);
		const storageDataWithAction = {
			action: 'email',
			country: this.setupPageService.getCountryCode(),
			embed_key: embedKey,
			blinds: getStorageData
		};
		
		this.serverDataService.PutSessionData(storageDataWithAction, this.setupSessionKeyForSavingHandler()).subscribe(() => {
			this.sessionStorageService.setBlindData(true, STORAGE_NAMES.zip_blind_progress_saved);
			this.sessionStorageSessionKeyHelper();
			
			const emailData = {
				email,
				session_key: this.setupSessionKeyForSavingHandler(),
				country: this.setupPageService.getCountryCode(),
			};
			
			this.serverDataService.PostEmailData(emailData).subscribe(() => {
				if (resolve) {
					resolve(true);
				}
			});
		});
	}
	
	putSaveBlindsPromise(email = '') {
		return new Promise((resolve) => {
			this.putSaveBlinds(email, resolve);
		});
	}
	
	putDownloadData(resolve?: (value: void) => void): void {
		const getStorageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const embedKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embed_key);
		const storageDataWithAction = {
			action: 'pdf',
			country: this.setupPageService.getCountryCode(),
			embed_key: embedKey,
			blinds: getStorageData
		};
		
		this.serverDataService.PutSessionData(
			storageDataWithAction,
			this.setupSessionKeyForSavingHandler()
		).subscribe(() => {
			this.sessionStorageSessionKeyHelper();
			
			const link =
				this.serverDataService.serverData().main_link +
				this.serverDataService.serverData().link.download + '/' +
				this.setupSessionKeyForSavingHandler();
			
			if (navigator.vendor === 'Apple Computer, Inc.') {
				this.serverDataService.getPdfData(link).then(() => resolve());
			} else {
				window.open(link, '_blank');
				resolve();
			}
		});
	}
	
	putDownloadDataPromise() {
		return new Promise(resolve => {
			this.putDownloadData(resolve);
		});
	}
	
	sessionStorageSessionKeyHelper(): void {
		this.sessionStorageService.setBlindData(this.setupSessionKeyForSavingHandler(), STORAGE_NAMES.zip_blind_session);
		this.shareService.setLinkSessionKey(this.setupSessionKeyForSavingHandler());
		
		this.sessionStorageService.setBlindData(true, STORAGE_NAMES.zip_blind_session_saved);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_temp_session);
	}
	
	setupSessionKeyForSavingHandler(): any {
		const hasSavedSession = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		const tempSessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_temp_session);
		const sessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);
		
		return hasSavedSession ? sessionKey : tempSessionKey;
	}
	
	emailBodyHandler(sessionKey): void {
		const isSessionSaved = window.location.href.includes(sessionKey);
		const getCountryCode = this.setupPageService.getCountryCode();
		
		const emailSpace = '%0D%0A%0D%0A';
		const emailAmpersand = '%26';
		const emailEqual = '%3D';
		const equalSignRegExp = new RegExp('=', 'g');
		const subject = 'Ziptrak® | Design Your Blind';
		const emailUpText = 'Check out my Ziptrak® blind design!';
		const emailDownText = 'This link gives you access to view and modify the design at any time';
		const countryCodeQuery = `${ emailAmpersand }country=${ getCountryCode }`;
		const emailLink = emailSpace + window.location.href + (!isSessionSaved ? `?=${ sessionKey }` : '') + countryCodeQuery + emailSpace;
		const emailBody = (`Hi,${ emailSpace }${ emailUpText }${ emailLink }${ emailDownText }.`).replace(equalSignRegExp, emailEqual);
		
		window.location.href = `mailto:?subject=${ subject }&body=${ emailBody }`;
	}
	
	preventReloadPage(status: boolean): void {
		window.onbeforeunload = status ? (e) => {
			e.returnValue = '';
			return status;
		} : () => { };
	}
}
