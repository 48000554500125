<div class="mc-blind-container">
	<div class="mc-blind-list" *ngIf="blindsData">
		<div class="mc-blind-item" data-id="{{blind.blind_id}}" [class]="blind.type">
			<div class="mc-blind-item__header">
				<div class="mc-blind-item__index">{{ blindIndex + 1 }}</div>
				<div class="mc-blind-item__name">
					{{ blind.default_name }}
					<span *ngIf="blind.name">: <br/>{{ blind.name }}</span>
				</div>
			</div>

			<div class="mc-blind-item__setup-container" *ngIf="blind.type === 'outdoor'">
				<div class="mc-blind-item__setup-content mc-config">
					<app-outdoor-list [index]="blindIndex" [blindsListComponent]="this"></app-outdoor-list>
				</div>
			</div>

			<div class="mc-blind-item__setup-container" *ngIf="blind.type === 'interior'">
				<div class="mc-blind-item__setup-content mc-config">
					<app-interior-list [index]="blindIndex" [blindsListComponent]="this"></app-interior-list>
				</div>
			</div>

		</div>
	</div>
</div>

<button class="mc-blind__button"
		(click)="saveChanges()"
		[disabled]="!hasChanges">{{ 'features_control.button_save' | translate }}
</button>
