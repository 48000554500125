<div class="mc-shutter-button"
	 *ngIf="hasBlinds && !isImageVisualisation"
	 (click)="onToggleShutterControl()"
	 [ngClass]="{'active': DYBShutterControlStatus}">
	<div class="mc-shutter-icon"></div>
</div>
<div class="mc-shutter-control"
	 [ngClass]="{'iv-screen': isImageVisualisation, 'mobile': isMobile}"
	 *ngIf="hasBlinds && IVShutterControlStatus && DYBShutterControlStatus">
	<div class="mc-shutter-icon"
		 #popover="ngbPopover"
		 container="body"
		 placement="right"
		 triggers="mousemove:mouseout"
		 popoverClass="mc-shutter-tooltip"
		 [ngbPopover]="'shutter.tooltip' | translate"></div>
	<mat-slider
			class="mc-shutter-slider"
			[vertical]="true"
			[(value)]="status"
			(input)="onShutterChange($event)"
			min="0" max="1"
			[step]="step"
	></mat-slider>
</div>
