import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { CONFIG, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';

import * as _ from 'lodash';

@Component({
	selector: 'app-shutter-control',
	templateUrl: './shutter-control.component.html',
	styleUrls: ['./shutter-control.component.scss', './shutter-control.responsive.scss']
})
export class ShutterControlComponent implements OnInit, OnDestroy {
	getViewType: Subscription;
	getIVShutterControlStatus: Subscription;
	getIVShutterControlClose: Subscription;
	getAccordingType: Subscription;
	getModelLoaded: Subscription;
	getSessionBlindsData: Subscription;
	
	isMobile: boolean;
	
	value = 1;
	step = 0.001;
	status: number;
	hasBlinds: boolean = !_.isEmpty(this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data));
	isImageVisualisation = false;
	IVShutterControlStatus = true;
	DYBShutterControlStatus = true;
	
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.DYBShutterControlStatus = this.isImageVisualisation || window.innerWidth > CONFIG.breakpoints['tablet-landscape'];
	}
	
	constructor(
		private engineService: EngineService,
		private shareService: ShareService,
		private setupPageService: SetupPageService,
		private sessionStorageService: SessionStorageService
	) {
	}
	
	ngOnInit(): void {
		this.shutterHandler();
		this.onResize();
		this.isMobile = this.setupPageService.mobileAndTabletCheck();
	}
	
	ngOnDestroy() {
		this.getAccordingType.unsubscribe();
		this.getModelLoaded.unsubscribe();
		this.getSessionBlindsData.unsubscribe();
		this.getViewType.unsubscribe();
	}
	
	onShutterChange(data): void {
		this.engineService.setTop(this.value - data.value);
	}
	
	shutterHandler(): void {
		this.getModelLoaded = this.shareService.getSceneCreated.subscribe(res => {
			this.hasBlinds = !_.isEmpty(this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data));
			this.closeShutter();
		});
		this.getAccordingType = this.shareService.getAccordionType.subscribe(this.closeShutter.bind(this));
		this.getSessionBlindsData = this.shareService.getSessionBlindsData.subscribe(res => this.hasBlinds = res);
		this.getViewType = this.shareService.getViewType.subscribe(type => {
			this.isImageVisualisation = type === VIEW_TYPES.image_visualisation;
			this.IVShutterControlStatus = type === VIEW_TYPES.design || this.IVShutterControlStatus;
			this.closeShutter();
			this.onResize();
		});
		this.getIVShutterControlStatus = this.shareService.getIVShutterControl.subscribe(status => {
			this.IVShutterControlStatus = status;
		});
		this.getIVShutterControlClose = this.shareService.getIVShutterControlClose.subscribe(status => {
			if (status) {
				this.closeShutter();
			}
		});
	}
	
	closeShutter(): void {
		this.status = 0;
		this.onShutterChange({ value: this.status });
	}
	
	onToggleShutterControl() {
		this.DYBShutterControlStatus = !this.DYBShutterControlStatus;
	}
}
