<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="close()"></div>
	<div class="mc-modal__title" [ngClass]="{'small': !emailSent}">
		{{ (emailSent ? 'modals.save_work_email.title_success' : 'modals.save_work_email.title') | translate }}
	</div>
	<div class="mc-modal__subtitle" *ngIf="emailSent">
		{{ 'modals.save_work_email.subtitle' | translate }}
	</div>

	<form class="mc-modal__form" (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="!emailSent">
		<div class="mc-modal__form-section">
			<input [ngClass]="{'mc-modal__form-section-input--error': submitted && f.email.errors}"
				   [placeholder]="'modals.save_work_email.inputs.email.placeholder' | translate"
				   class="mc-modal__form-section-input"
				   formControlName="email"
				   id="email" required type="text">

			<div *ngIf="submitted && f.email.errors && f.email.errors['required']"
				 class="mc-modal__form-section-message">
				{{ 'modals.save_work_email.inputs.email.errors.required' | translate }}
			</div>
			<div *ngIf="submitted && f.email.errors && f.email.errors['pattern']"
				 class="mc-modal__form-section-message">
				{{ 'modals.save_work_email.inputs.email.errors.wrongFormat' | translate }}
			</div>
		</div>
		<button type="submit" class="mc-modal__button" [ngClass]="{'loading': saving}">
			{{ 'modals.save_work_email.buttons.send' | translate }}
		</button>
	</form>

	<div class="mc-modal__button mc-modal__button--close" *ngIf="emailSent" (click)="close()">
		{{ 'modals.save_work_email.buttons.ok' | translate }}
	</div>
</div>
