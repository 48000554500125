<div class="mc-modal__quiz">
	<div class="mc-modal__close" (click)="closeDialog()"></div>

	<ngx-slick-carousel class="carousel mc-modal__quiz-carousel"
						#slickCarousel="slick-carousel"
						(init)="carouselHandler($event)"
						(afterChange)="carouselHandler($event)"
						[config]="slideConfig">
		<div class="mc-modal__quiz-carousel-item" ngxSlickItem *ngIf="!isMobile">
			<div class="mc-modal__quiz-text">{{ 'modals.quiz.place' | translate }}</div>
			<img class="mc-modal__quiz-img" [src]="quizIconsFolder + 'place.svg'"/>
		</div>
		<div class="mc-modal__quiz-carousel-item" ngxSlickItem>
			<div class="mc-modal__quiz-text">{{ (isMobile ? 'modals.quiz.move_mobile' : 'modals.quiz.move') | translate }}</div>
			<img class="mc-modal__quiz-img" [src]="quizIconsFolder + 'move.svg'"/>
		</div>
		<div class="mc-modal__quiz-carousel-item" ngxSlickItem>
			<div class="mc-modal__quiz-text">{{ (isMobile ? 'modals.quiz.resize_mobile' : 'modals.quiz.resize') | translate }}</div>
			<img class="mc-modal__quiz-img" [src]="quizIconsFolder + 'resize.svg'"/>
		</div>
		<div class="mc-modal__quiz-carousel-item" ngxSlickItem>
			<div class="mc-modal__quiz-text">{{ (isMobile ? 'modals.quiz.rotate_mobile' : 'modals.quiz.rotate') | translate }}</div>
			<img class="mc-modal__quiz-img" [src]="quizIconsFolder + 'rotate.svg'"/>
		</div>
		<div class="mc-modal__quiz-carousel-item" ngxSlickItem>
			<div class="mc-modal__quiz-text">{{ 'modals.quiz.send' | translate }}</div>
			<object class="mc-modal__quiz-img" type="image/svg+xml" [data]="sendSVG"></object>
		</div>
	</ngx-slick-carousel>

	<div class="mc-modal__quiz-buttons" *ngIf="slidesQuantity">
		<button class="mc-modal__quiz-button"
				type="button"
				[disabled]="!currentSlideIndex"
				(click)="slickCarousel.slickPrev()">
			{{ 'modals.quiz.buttons.back' | translate }}
		</button>
		<button class="mc-modal__quiz-button"
				type="button"
				(click)="currentSlideIndex !== slidesQuantity - 1 ? slickCarousel.slickNext() : closeDialog()">
			{{ 'modals.quiz.buttons.next' | translate }}
		</button>
	</div>
</div>
