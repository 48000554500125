<div class="mc-modal__ar-loading">
	<div class="mc-modal__ar-loading-header">
		<div class="mc-modal__ar-loading-header-logo" (click)="onClose('logo')"></div>
		<div class="mc-modal__ar-loading-header-close"
			 *ngIf="!data || data?.model_exported"
			 (click)="onClose('close')"></div>
	</div>
	<div class="mc-modal__ar-loading-loader"
		 [class]="{'loaded': isArLoadedOnAndroid && !isARNotCompatible && !loading,
                   'not-compatible': isARNotCompatible && !loading}"></div>
	<div class="mc-modal__ar-loading-text">
		{{
			(loading ? 'modals.ar_loading.text' : isArLoadedOnAndroid && !isARNotCompatible ?
				'modals.ar_loading.text_loaded' : 'modals.ar_loading.not_compatible') | translate
		}}
	</div>
</div>
