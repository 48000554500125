import {
	ChangeDetectorRef,
	Component, ElementRef,
	EventEmitter,
	Input,
	OnChanges, OnDestroy,
	OnInit,
	Output,
	SimpleChanges, ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { EngineService } from '@core/services/engine/engine.service';
import { INTERIOR_COLOR_FIXTURES, INTERIOR_FRAME_TOP_STYLE, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';

declare var $: any;

@Component({
	selector: 'app-fixtures',
	templateUrl: './fixtures.component.html',
	styleUrls: ['./fixtures.component.scss', './fixtures.responsive.scss']
})
export class FixturesComponent implements OnInit, OnChanges, OnDestroy {
	@Input() blindId;
	@Output() fixturesData = new EventEmitter();
	@ViewChild('fixturesList') fixturesList: ElementRef;
	
	getBlindSelect: Subscription;
	getBlindSize: Subscription;
	serverFixturesData;
	storageData = {
		fixtures_color: {
			id: '',
			checked: false
		}
	};
	blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
	sessionConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
	sessionText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);
	isDesignType: boolean =
		this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
	
	selectors = {
		list: '.mc-fixtures__list',
		input: '.mc-fixtures__input'
	};
	defaultId;
	
	constructor(
		private sessionStorageService: SessionStorageService,
		private shareDataService: ShareService,
		private saveSessionBlind: SaveSessionBlindsService,
		private engineService: EngineService,
		private changeDetection: ChangeDetectorRef
	) {
	}
	
	ngOnInit(): void {
		this.serverFixturesData = this.sessionConfig?.[this.blindType].fixtures_color;
		
		this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(this.setBlindSelectId.bind(this));
		this.getBlindSize = this.shareDataService.getBlindSizeChanged.subscribe(this.setBlindSelectId.bind(this));
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		const getCurrentBLindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		
		if (changes.blindId.currentValue === getCurrentBLindId) {
			setTimeout(this.setDefaultValue.bind(this));
		}
	}
	
	ngOnDestroy(): void {
		this.getBlindSelect.unsubscribe();
	}
	
	getStorageFixtures(id?: number): void {
		const fixturesStorage = this.sessionStorageService.getBlindItemById(id || this.blindId, STORAGE_NAMES.zip_blind_data);
		
		if (fixturesStorage) {
			for (const item of fixturesStorage) {
				if (item.setup && item.setup.fixtures_color) {
					this.storageData.fixtures_color = this.serverFixturesData?.find(x => x.id === item.setup.fixtures_color.id);
					this.modelManagingHandler(this.storageData.fixtures_color.id, 'storage');
				}
			}
		}
	}
	
	onSubmitRadioOptions(event: Event, id: number): void {
		const fixturesList = this.fixturesList.nativeElement.children;
		for (const item of fixturesList) {
			item.classList.remove('active');
		}
		
		const activeItem = Array.from(fixturesList).find((item: HTMLElement) => +item.id === id) as HTMLElement;
		activeItem.classList.add('active');
		this.modelManagingHandler(id, event);
	}
	
	modelManagingHandler(operationId, event): void {
		const selectedBlind = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0];
		const selectedBlindSizes = selectedBlind.setup.size;
		const selectedBlindFrameColor = selectedBlind.setup.frames.frame_color;
		const getCurrentData = this.serverFixturesData.find(x => x.id === +operationId);
		
		if (selectedBlindSizes) {
			const width = selectedBlindSizes['width'];
			const height = selectedBlindSizes['height'];
			const topStyleId = INTERIOR_FRAME_TOP_STYLE.reduce((acc, x) => !acc && width >= x.width && height >= x.height ? x.id : acc, '');
			const topStyle = this.sessionConfig[this.blindType].frame.top_style.filter(el => el.id === topStyleId)[0];
			
			for (const meshId of INTERIOR_COLOR_FIXTURES) {
				if (!meshId.includes('CAP') || topStyle.id === INTERIOR_FRAME_TOP_STYLE[2].id) {
					this.engineService.setColor(meshId, getCurrentData.color, 1, 'frame');
				} else {
					this.engineService.setColor(meshId, selectedBlindFrameColor.color, 1, 'frame');
				}
			}
		}
		
		if (+this.storageData.fixtures_color.id !== +operationId) {
			const isClick = typeof event !== 'string';
			this.storageData.fixtures_color = { ...this.storageData.fixtures_color, ...getCurrentData };
			this.saveBlindSetup(isClick);
		}
		
		this.checkingRadio(getCurrentData);
	}
	
	setBlindSelectId(data): void {
		this.blindType = this.sessionStorageService.getBlindItemById(data?.id, STORAGE_NAMES.zip_blind_data)[0]?.type || this.blindType;
		this.serverFixturesData = this.sessionConfig[this.blindType].fixtures_color;
		
		if (data.id === this.blindId && this.blindType === 'interior') {
			this.getStorageFixtures(data.id);
		}
		
		this.changeDetection.markForCheck();
	}
	
	setDefaultValue(): void {
		const fixturesConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config)[this.blindType].fixtures_color;
		const fixturesStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data);
		
		if ((fixturesStorage?.[0].setup.fixtures_color || _.isEmpty(fixturesConfig)) && this.isDesignType) {
			return;
		}
		
		if (fixturesStorage?.[0].setup.fixtures_color && !this.isDesignType) {
			this.storageData.fixtures_color = fixturesStorage?.[0].setup.fixtures_color;
			return;
		}
		
		for (const item of fixturesConfig) {
			if (item.is_default) {
				this.modelManagingHandler(item.id, 'loading');
				this.defaultId = item.id;
			}
		}
	}
	
	checkingRadio(operationData): void {
		const inputId = `${ this.blindId }${ operationData.name.split(' ').join('') }`;
		
		$(`#${ inputId }`).prop('checked', true);
	}
	
	onPopover(popover: NgbPopover, button: HTMLElement): void {
		$(button).toggleClass('active', popover.isOpen());
	}
	
	saveBlindSetup(status: boolean = true): void {
		const isDesignType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
		const storage = isDesignType ? STORAGE_NAMES.zip_blind_data : STORAGE_NAMES.zip_blind_data_iv;
		
		this.fixturesData.emit(this.storageData);
		this.sessionStorageService.setBlindSetupDataById(this.blindId, this.storageData, storage);
		
		if (isDesignType) {
			this.saveSessionBlind.PutStorageDataToServer();
		}
		
		if (!this.isDesignType && status) {
			this.shareDataService.setIVDataChanged(true);
		}
	}
}
