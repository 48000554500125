<div class="mc-frame__container">
	<div class="mc-frame__item">
		<div class="mc-frame__item-header">
			{{ sessionText.frame_colour_title }}
			<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
				  #framePopover="ngbPopover" #framePopoverButton
				  (shown)="onPopover(framePopover, framePopoverButton)"
				  (hidden)="onPopover(framePopover, framePopoverButton)"
				  placement="top auto"
				  container="body"
				  popoverClass="mc-popover mc-popover-blind-info"
				  [ngbPopover]="sessionText['frame_colour_pop_up_text' + (blindType === 'interior' ? '_interior' : '')]"></span>
		</div>
		<div class="mc-frame__item-content" [ngClass]="{'one-row': topStyle.length <= 3}" #frameColorItem>
			<div class="mc-frame__item-block"
				 *ngFor="let frame of frames"
				 (click)="onSelectColor(frame)"
				 [attr.data-color]="frame.color"
				 [ngClass]="{'active': !frameColorObject.is_custom &&
                                       ((frame.id === frameColorObject && frameColorObject.id)
                                       || (frame.id === frameColorObject.id)
                                       || (frame.is_default && !frameColorObject.name))}">
				<div class="mc-frame__item-block--new" *ngIf="frame.status === 'new'">new</div>
				<div class="mc-frame__item-block--circle"
					 [ngStyle]="{'background': 'linear-gradient(315deg,' + frame.color +' 58.49%, ' + frame.color + '80 100%)',
                                 'box-shadow': 'inset -4px -4px 20px rgba(0, 0, 0, 0.2)'}"></div>
				{{ frame.name }}
			</div>
			<div class="mc-frame__item-block other"
				 [ngClass]="{'active': frameColorObject.name === 'Other'}"
				 [attr.data-blind-id]="blindId"
				 [attr.data-color]="otherColor">
				<div class="mc-frame__item-block--circle"
					 [ngStyle]="{'background': 'linear-gradient(315deg,' + otherColor +' 58.49%, ' + otherColor + '80 100%)'}">
					<app-color-picker
							[data]="{id: 99, name: 'Other'}"
							[colors]="frameColors"
							[placement]="'top bottom'"
							(setColorData)="onSelectColor($event)">
					</app-color-picker>
					<div class="mc-frame__item-block--other" [style.background]="otherColor ? 'transparent' : ''"></div>
				</div>
				Other
			</div>
		</div>
		<div class="mc-frame__custom-color" *ngIf="blindType === 'outdoor'">
			<div class="mc-frame__item-header">
				Frame Custom Colour
				<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
					  #popover="ngbPopover" #popoverButton
					  (shown)="onPopover(popover, popoverButton)"
					  (hidden)="onPopover(popover, popoverButton)"
					  placement="top auto"
					  container="body"
					  popoverClass="mc-popover mc-popover-blind-info"
					  ngbPopover="Frame Custom Colour"></span>
			</div>
			<div class="mc-frame__custom-color-inputs">
				<input type="checkbox"
					   [id]="'mc-frame__custom-color-checkbox-' + blindId + random"
					   [checked]="frameColorObject.is_custom"
					   (change)="onInputCustomColor($event, newCustomColor.value)"
					   class="mc-frame__input">
				<label [for]="'mc-frame__custom-color-checkbox-' + blindId + random"
					   class="mc-frame__label">
					<span class="mc-frame__checkbox" tabindex="0"></span>
				</label>
				<input #newCustomColor
					   class="mc-frame__custom-color-input"
					   [id]="blindId + random"
					   type="text"
					   value="{{frameColorObject.is_custom ? frameColorObject.color : customColor}}"
					   (focus)="onInputCustomColor($event, newCustomColor.value)"
					   (keyup)="onInputCustomColor($event, newCustomColor.value)"
					   (blur)="onInputCustomColor($event, newCustomColor.value)"
					   placeholder="Enter Custom Colour Name">
			</div>
		</div>
	</div>
	<div class="mc-frame__item" *ngIf="blindType === 'outdoor'">
		<div class="mc-frame__item-header">
			{{ sessionText.top_style_title }}
			<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
				  #stylePopover="ngbPopover" #stylePopoverButton
				  (shown)="onPopover(stylePopover, stylePopoverButton)"
				  (hidden)="onPopover(stylePopover, stylePopoverButton)"
				  placement="top auto"
				  container="body"
				  popoverClass="mc-popover mc-popover-blind-info"
				  [ngbPopover]="sessionText.top_style_pop_up_text"></span>
		</div>
		<div class="mc-frame__item-content" [ngClass]="{'one-row': topStyle.length <= 4}" #frameTopStyleItem>
			<div class="mc-frame__item-block image"
				 *ngFor="let item of topStyle"
				 (click)="onSelectTopStyle(item, $event)"
				 [ngClass]="{'active': (item?.id === frameTopStyleObject && frameTopStyleObject?.id) || (item?.id === frameTopStyleObject?.id) || (item?.is_default && !frameTopStyleObject?.name)}">
				<div class="mc-frame__item-block--new" *ngIf="item?.status === 'new'">new</div>
				<div class="mc-frame__item-block--circle"
					 [ngStyle]="{'background-image': 'url(' + item?.image + ')'}"></div>
				{{ item?.name }}
			</div>
		</div>
	</div>
	<div class="mc-frame__item" *ngIf="blindType === 'outdoor'">
		<div class="mc-frame__item-header">
			{{ sessionText.bottom_bar_protection_title }}
			<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
				  #barPopover="ngbPopover" #barPopoverButton
				  (shown)="onPopover(barPopover, barPopoverButton)"
				  (hidden)="onPopover(barPopover, barPopoverButton)"
				  placement="top auto"
				  container="body"
				  popoverClass="mc-popover mc-popover-blind-info"
				  [ngbPopover]="sessionText.bottom_bar_protection_pop_up_text"></span>
		</div>
		<div class="mc-frame__item-content" [ngClass]="{'one-row': bottomBar.length <= 4}" #frameBottomBarItem>
			<div class="mc-frame__item-block image"
				 *ngFor="let item of bottomBar"
				 (click)="onSelectBottomBar(item, $event)"
				 [ngClass]="{'active': (item?.id === frameBottomBarObject && frameBottomBarObject?.id) || (item?.id === frameBottomBarObject?.id) || (item?.is_default && !frameBottomBarObject?.name)}">
				<div class="mc-frame__item-block--new" *ngIf="item?.status === 'new'">new</div>
				<div class="mc-frame__item-block--circle"
					 [ngStyle]="{'background-image': 'url(' + item?.image + ')'}"></div>
				{{ item?.name }}
			</div>
		</div>
	</div>
	<div class="mc-frame__item" *ngIf="blindType === 'interior'">
		<div class="mc-frame__item-header">Optional</div>
		<div *ngFor="let option of optionals" class="mc-frame__item-optional-block">
			<input type="checkbox" class="mc-frame__input" id="{{blindId}}{{option.name | replace:' ':''}}{{random}}"
				   name="{{option.name}}" [(ngModel)]="option.is_checked">
			<label class="mc-frame__label"
				   for="{{blindId}}{{option.name | replace:' ':''}}{{random}}"
				   data-id="{{option.id}}"
				   (click)="onSubmitCheckboxOptions($event)">
				<span class="mc-frame__checkbox" tabindex="0"></span>
				<span class="mc-frame__label-text">{{ option.name }}</span>
			</label>
		</div>
	</div>
</div>
