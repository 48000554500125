import { Injectable } from '@angular/core';
import { dedup, prune } from '@gltf-transform/functions';
import { ALL_EXTENSIONS } from '@gltf-transform/extensions';
import { WebIO } from '@gltf-transform/core';

@Injectable({
	providedIn: 'root'
})
export class CompressModelService {
	
	constructor() {
	}
	
	async compress(blob: Blob): Promise<Blob> {
		const arr = new Uint8Array(await blob.arrayBuffer());
		const io = new WebIO().registerExtensions(ALL_EXTENSIONS);
		const doc = await io.readBinary(arr);
		await doc.transform(
			dedup(),
			prune(),
		);
		
		const glb = await io.writeBinary(doc);
		
		return new Blob([glb]);
	}
	
}
