<div class="mc-blind-container">
	<div class="mc-blind__swipe-area"
		 (touchstart)="onTouchEvent($event)"
		 (touchmove)="onTouchEvent($event)"
		 (touchend)="onTouchEvent($event)">
		<div class="mc-blind-mobile-slider"
             [class]="{'arrow': !isMobile}"
             *ngIf="blindsData && blindsData.length">
            <div class="mc-blind-mobile-arrow"
                 *ngIf="!isMobile"
                 [class]="{'opened': blindListOpened}"
                 (click)="onPanel($event)"></div>
        </div>
		<div class="mc-blind-list" *ngIf="blindsData && blindsData.length" #blindListContainer
			 [ngClass]="{'open': blindListOpened}">
			<div class="mc-blind-item"
				 *ngFor="let blind of blindsData; let i = index"
				 data-id="{{blind.blind_id}}" [class]="blind.type"
				 [ngClass]="{'mobile-selected': mobileSelectedBlindId === blind.blind_id}">
				<div class="mc-blind-item__header" (click)="accordionBlindItems($event, blind.blind_id, blind.type)">
					<div class="mc-blind-item__index">{{ i + 1 }}</div>
					<div class="mc-blind-item__name" *media="breakpoints['desktop'].min">
						{{ blind.default_name }}
						<span *ngIf="blind.name">: <br/>{{ blind.name }}</span>
					</div>
					<div class="mc-blind-item__name" *media="breakpoints['tablet-portrait'].max">
						{{ blind.type | titlecase }}<span *ngIf="blind.name">: {{ blind.name }}</span>
					</div>
					<span class="mc-blind-item__setup-copy"
						  #popover="ngbPopover"
						  placement="bottom"
						  triggers="mouseenter:mouseleave"
						  container="body"
						  popoverClass="mc-popover mc-popover-image-visualisation"
						  [ngClass]="copyPopover.isOpen() ? 'active' : ''"
						  [ngbPopover]="!isMobile && !copyPopover.isOpen() ? ('modals.copy_blind.tooltip' | translate) : null">
						<div #copyPopover="ngbPopover"
							 container="body"
							 placement="left"
							 (click)="$event.stopPropagation()"
							 [ngbPopover]="copyBlindContent"
							 popoverClass="mc-popover mc-popover-copy">
							Copy
						</div>
					</span>
					<span class="mc-blind-item__setup-delete"
						  #popover="ngbPopover"
						  placement="bottom"
						  triggers="mouseenter:mouseleave"
						  container="body"
						  popoverClass="mc-popover mc-popover-image-visualisation"
						  [ngClass]="deletePopover.isOpen() ? 'active' : ''"
						  [ngbPopover]="!isMobile && !deletePopover.isOpen() ? ('modals.delete_blind.tooltip' | translate) : null">
						<div #deletePopover="ngbPopover"
							 placement="left"
							 container="body"
							 (click)="$event.stopPropagation()"
							 [ngbPopover]="deleteBlindContent"
							 popoverClass="mc-popover mc-popover-delete">
							Delete
						</div>
					</span>
					<div class="mc-blind-item__arrow">Arrow</div>
				</div>

				<div class="mc-blind-item__setup-container hidden" *ngIf="blind.type === 'outdoor'">
					<div class="mc-blind-item__setup-content mc-config">
						<app-outdoor-list [index]="i" [blindsListComponent]="this"></app-outdoor-list>
					</div>
				</div>

				<div class="mc-blind-item__setup-container hidden" *ngIf="blind.type === 'interior'">
					<div class="mc-blind-item__setup-content mc-config">
						<app-interior-list [index]="i" [blindsListComponent]="this"></app-interior-list>
					</div>
				</div>

				<ng-template #deleteBlindContent>
					<div class="mc-blind-item__delete-title">{{ 'modals.delete_blind.title' | translate }}</div>
					<button class="mc-blind-item__button yes" (click)="deleteBlindItem(blind.blind_id)">
						{{ 'modals.delete_blind.buttons.yes' | translate }}
					</button>
					<button class="mc-blind-item__button no"
							(click)="[deletePopover.close(), $event.stopPropagation()]">
						{{ 'modals.delete_blind.buttons.no' | translate }}
					</button>
				</ng-template>

				<ng-template #copyBlindContent>
					<div class="mc-blind-item__delete-title">{{ 'modals.copy_blind.title' | translate }}</div>
					<button class="mc-blind-item__button yes" (click)="copyBlindItem(blind.blind_id)">
						{{ 'modals.delete_blind.buttons.yes' | translate }}
					</button>
					<button class="mc-blind-item__button no"
							(click)="[copyPopover.close(), $event.stopPropagation()]">
						{{ 'modals.delete_blind.buttons.no' | translate }}
					</button>
				</ng-template>
			</div>
		</div>
	</div>
	<app-add-blind></app-add-blind>
</div>
