<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="close()"></div>
	<div class="mc-modal__title">{{ 'modals.overview.title' | translate }}</div>
	<div class="mc-modal__subtitle">
		{{ 'modals.overview.subtitle' | translate }}
	</div>
	<div class="mc-modal__buttons">
		<div class="mc-modal__button mc-modal__button--yellow" (click)="onSeeOverviewButton()">
			{{ 'modals.overview.buttons.see' | translate }}
		</div>
	</div>
</div>
