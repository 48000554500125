<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="close()"></div>
	<div class="mc-modal__title">{{ 'modals.save_work.title' | translate }}</div>
	<div class="mc-modal__subtitle">
		{{ (embeddedRetailer ? 'modals.save_work.subtitle_embedded_retailer' : 'modals.save_work.subtitle') | translate }}
	</div>
	<div class="mc-modal__buttons">
		<div class="mc-modal__button mc-modal__button--yellow mc-modal__button--download" (click)="onDownloadImage()"
			 [ngClass]="{'loading': downloading, 'embedded-retailer': embeddedRetailer}">
			{{ 'modals.save_work.buttons.download' | translate }}
		</div>
		<div class="mc-modal__button mc-modal__button--save" (click)="onSaveWork()" [ngClass]="{'loading': saving}"
			 *ngIf="!embeddedRetailer">
			{{ 'modals.save_work.buttons.save' | translate }}
		</div>
	</div>
</div>
