import { MatDialogConfig } from '@angular/material/dialog';
import { STORAGE_NAMES } from '@root/app.config';

const isIVBackgroundSampleProject = () => {
	const ivBackgroundImage = JSON.parse(sessionStorage.getItem(STORAGE_NAMES.zip_image_visualisation_background));
	return ivBackgroundImage?.type;
};

type DialogConfig = {
	[propName: string]: MatDialogConfig
};

export function getModalsConfig(): DialogConfig {
	const isSampleProject = isIVBackgroundSampleProject();
	
	return {
		ArLoadingModalComponent: {
			width: '100vw',
			maxWidth: '100vw',
			height: '100vh',
			disableClose: true,
			closeOnNavigation: false
		},
		QuizModalComponent: {
			id: 'quiz-modal',
			panelClass: ['mc-modal__quiz-pane', isSampleProject ? 'sample-project' : ''],
			hasBackdrop: !isSampleProject,
			backdropClass: !isSampleProject ? 'mc-modal__quiz-backdrop' : null,
			disableClose: !isSampleProject,
			maxWidth: '100vw'
		},
		SelectBackgroundModalComponent: {
			width: 'max-content',
			maxWidth: '940px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		},
		RotateDeviceModalComponent: {
			width: 'max-content',
			maxWidth: '450px',
			height: 'auto'
		},
		SaveWorkEmailModalComponent: {
			width: '100%',
			maxWidth: '690px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		},
		DisabledLinkModalComponent: {
			width: '100vw',
			maxWidth: '100vw',
			height: '100vh',
			panelClass: ['mc-modal__internal', 'mc-modal__full-screen']
		}
	};
}
