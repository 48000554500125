import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CONFIG, ROTATE_TYPES, SELECTORS, VIEW_TYPES, STORAGE_NAMES } from '@root/app.config';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { ModalService } from '@core/services/modal/modal.service';
import { RotateDeviceModalComponent } from '@shared/modals/rotate-device-modal/rotate-device-modal.component';
import { ArLoadingModalComponent } from '@shared/modals/ar-loading-modal/ar-loading-modal.component';

@Component({
	selector: 'app-select-background-modal',
	templateUrl: './select-background-modal.component.html',
	styleUrls: ['./select-background-modal.component.scss', './select-background-modal.responsive.scss']
})
export class SelectBackgroundModalComponent implements OnInit, OnDestroy {
	@ViewChild('formElement') formElement: ElementRef;
	
	isMobile: boolean = this.passDataService.isMobileOrTablet;
	getProductType: string;
	selectedBackgroundType = '';
	allowedMimeType: string[] = ['image/png', 'image/jpeg', 'image/jpg'];
	maxFileSize = 10000000;
	maxSizeError = false;
	fileTypeError = false;
	imageWidth;
	imageHeight;
	sampleStatus;
	sampleType;
	sampleIndex;
	form: FormGroup = this.fb.group({
		photo: null,
		photo_base64: null,
		photo_blob: null
	});
	
	imageRoute = 'assets/images/';
	projectSamples = {
		outdoor: [this.imageRoute + 'outdoor-1.png', this.imageRoute + 'outdoor-2.png', this.imageRoute + 'outdoor-3.png'],
		interior: [this.imageRoute + 'interior-1.png', this.imageRoute + 'interior-2.png', this.imageRoute + 'interior-3.png'],
	};
	
	slideConfig = {
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: false,
		infinite: false,
		variableWidth: true,
		mobileFirst: false,
		arrows: false,
		responsive: [
			{
				breakpoint: CONFIG.breakpoints['phone'],
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}
		]
	};
	
	constructor(
		private fb: FormBuilder,
		private engineService: EngineService,
		private shareService: ShareService,
		private setupPageService: SetupPageService,
		private sessionStorageService: SessionStorageService,
		private passDataService: PassDataService,
		private modalService: ModalService,
		private matDialog: MatDialog,
		public dialogRef: MatDialogRef<SelectBackgroundModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {
	}
	
	ngOnInit() {
		this.getProductType = this.setupPageService.getProductType();
		this.engineService.setGizmoControlVisible(false);
		this.modalService.liftUpModal(this.dialogRef);
	}
	
	ngOnDestroy() {
		this.engineService.setGizmoControlVisible(true);
		this.setViewType();
	}
	
	onSelectType(type: string) {
		this.selectedBackgroundType = type;
		this.scrollToForm();
		this.onDeleteFile();
	}
	
	onSelectProjectSample(event: Event, sampleProject: string, type: string, index: number) {
		const target = event.currentTarget as HTMLElement;
		this.sampleStatus = true;
		this.sampleType = type;
		this.sampleIndex = index;
		
		document.querySelector(SELECTORS.background_project_samples).classList.remove('active');
		target.classList.add('active');
		
		fetch(sampleProject)
			.then((res) => res.blob())
			.then((blob) => this.uploadFile(blob));
		
		this.scrollToElement(this.formElement.nativeElement.querySelector('button[type=submit]'));
	}
	
	onSelectFile(event: Event) {
		const input = event.target as HTMLInputElement;
		const fileArray = this.convertFileList(input.files);
		
		if (fileArray.length) {
			this.sampleType = null;
			this.sampleIndex = null;
			this.uploadFile(fileArray[0]);
		}
		
		if (input.id === 'photo') {
			this.scrollToForm();
		}
	}
	
	onDropFile(event) {
		event.preventDefault();
		event.stopPropagation();
		event.target.classList.remove('drag-over');
		
		const fileArray = this.convertFileList(event.dataTransfer.files);
		
		if (fileArray.length) {
			this.uploadFile(fileArray[0]);
		}
	}
	
	onDragEvent(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		
		if (event.type === 'dragover') {
			(event.target as HTMLElement).classList.add('drag-over');
		} else if (event.type === 'dragleave') {
			(event.target as HTMLElement).classList.remove('drag-over');
		}
	}
	
	convertFileList(files: FileList): File[] {
		this.fileTypeError = false;
		this.maxSizeError = false;
		
		return [...files].filter((file: File) => {
			const isAllowedType = this.allowedMimeType.some((type: string) => type === file.type);
			const isCorrectFileSize = file.size <= this.maxFileSize;
			
			if (!isAllowedType) {
				this.fileTypeError = true;
			} else if (!isCorrectFileSize) {
				this.maxSizeError = true;
			}
			
			return isAllowedType && isCorrectFileSize;
		});
	}
	
	uploadFile(file: File | Blob) {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.form.controls.photo_base64.setValue(reader.result);
		};
		
		const image = new Image();
		image.src = window.URL.createObjectURL(file);
		image.onload = () => {
			this.form.controls.photo_blob.setValue(image.src);
			this.imageHeight = image.height;
			this.imageWidth = image.width;
		};
	}
	
	onDeleteFile(type?: string) {
		this.form.controls.photo.reset();
		this.form.controls.photo_base64.reset();
		this.form.controls.photo_blob.reset();
		this.fileTypeError = false;
		this.maxSizeError = false;
		
		if (type === 'photo') {
			this.selectedBackgroundType = '';
		}
	}
	
	onSubmit() {
		if (this.form.valid) {
			const backgroundImage = {
				image: this.form.value.photo_blob,
				width: this.imageWidth,
				height: this.imageHeight,
				type: this.sampleType,
				index: this.sampleIndex,
			};
			
			this.engineService.setBackgroundImage(backgroundImage);
			this.setViewType(VIEW_TYPES.image_visualisation);
			this.onClose();
			
			this.openRotateDeviceModal();
			this.passDataService.isLiveOpened = false;
		}
	}
	
	onClose(): void {
		this.dialogRef.close();
	}
	
	scrollToForm() {
		if (window.orientation === 0 && window.innerWidth <= CONFIG.breakpoints['phone'] ||
			window.orientation === 90 && window.innerWidth <= CONFIG.breakpoints['tablet-landscape']) {
			setTimeout(this.scrollToElement.bind(this, this.formElement.nativeElement));
		}
	}
	
	trackByIndex(index: number): number {
		return index;
	}
	
	openLive(): void {
		const dialog = this.matDialog.open(ArLoadingModalComponent, this.modalService.getConfig(ArLoadingModalComponent.name));
		
		this.engineService.addVideoLayer().then(() => {
			this.onClose();
			dialog.close();
		}).catch(() => {
			dialog.close();
		});
	}
	
	openRotateDeviceModal(): void {
		const rotateType = window.innerWidth < window.innerHeight && this.imageWidth > this.imageHeight ? ROTATE_TYPES.to_landscape :
			window.innerWidth > window.innerHeight && this.imageWidth < this.imageHeight ? ROTATE_TYPES.to_portrait : '';
		
		if (this.isMobile && rotateType) {
			this.matDialog.open(
				RotateDeviceModalComponent,
				this.modalService.getConfig(RotateDeviceModalComponent.name, rotateType)
			);
		}
	}
	
	scrollToElement(element: HTMLElement): void {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}
	
	setViewType(type?: string): void {
		type ||= this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type);
		this.shareService.setViewType(type);
	}
}
