import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input, OnChanges,
	OnDestroy,
	OnInit,
	Output, SimpleChanges
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { INTERIOR_FRAME_TOP_STYLE, STORAGE_NAMES, SELECTORS, VIEW_TYPES } from '@root/app.config';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import * as _ from 'lodash';

declare var $: any;

@Component({
	selector: 'app-size-change',
	templateUrl: './size-change.component.html',
	styleUrls: ['./size-change.component.scss', './size-change.responsive.scss']
})
export class SizeChangeComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
	@Input() blindId;
	@Output() sizeData = new EventEmitter();
	
	getBlindSelect: Subscription;
	getMaterialType: Subscription;
	getOperationName: Subscription;
	getSceneCreated: Subscription;
	
	storageSizes = {
		size: {}
	};
	
	storageMaterialType;
	serverSizeData;
	currentStorageMaterialType;
	
	modelSize;
	valueWidth;
	valueHeight;
	modelSizeVert;
	colorType: string;
	getBlindStatus: number | string;
	modelType: string;
	operationName: string;
	currentBlindId: number;
	
	sizeValue = {
		width: '',
		height: ''
	};
	
	dataValue = {
		width: '',
		height: ''
	};
	blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
	sessionConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
	defaultSizeData = this.sessionStorageService.getModelSize(STORAGE_NAMES.default_model_size);
	isDesignType: boolean =
		this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
	
	setServerData = false;
	hasExtraSizeWidth = false;
	hasExtraSizeHeight = false;
	firstOpenSizeError = false;
	
	constructor(
		public engineService: EngineService,
		public sessionStorageService: SessionStorageService,
		public shareDataService: ShareService,
		public saveSessionBlind: SaveSessionBlindsService,
		public screenShotService: ScreenshotService,
		public changeDetection: ChangeDetectorRef
	) {
	}
	
	ngOnInit(): void {
		if (this.sessionConfig) {
			this.getStorageMaterialType();
			this.getServerDefaultData();
			this.getStorageSizes();
		}
		
		this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(res => {
			this.getBlindStatus = res.id;
			this.modelType = this.modelType || res?.type;
			
			if (this.modelType && (this.modelType === res?.type)) {
				this.setBlindSelectId(res);
			}
		});
		this.getOperationName = this.shareDataService.getOperationName.subscribe(res => this.operationName = res);
		this.getMaterialType = this.shareDataService.getMaterialType.subscribe(res => {
			this.colorType = res;
			if (this.modelType) {
				this.setMaterialTypeSize(this.colorType);
			}
		});
		this.getSceneCreated = this.shareDataService.getSceneCreated.subscribe(res => {
			this.modelType = res;
			const getCurrentBLindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
			
			if (this.currentBlindId === getCurrentBLindId) {
				this.setMaterialTypeSize(this.colorType);
				this.setBlindSelectId({ id: this.getBlindStatus });
			}
		});
	}
	
	ngOnChanges(changes: SimpleChanges) {
		this.currentBlindId = changes.blindId.currentValue;
	}
	
	ngAfterViewChecked(): void {
		if (this.hasExtraSizeWidth || this.hasExtraSizeHeight) {
			this.openSizeItem();
		}
	}
	
	ngOnDestroy(): void {
		this.getBlindSelect.unsubscribe();
		this.getMaterialType.unsubscribe();
		this.getSceneCreated.unsubscribe();
	}
	
	setCorrectSizesHandler(data): void {
		for (const item of data) {
			if (this.storageMaterialType === item.type) {
				this.serverSizeData = item.sizes;
			}
		}
	}
	
	getStorageSizes(): void {
		const sizeStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data);
		
		if (sizeStorage) {
			for (const item of sizeStorage) {
				if (item.setup && item.setup.size) {
					this.storageSizes.size = item.setup.size;
					if (this.blindType === 'interior' && item.type === 'interior') {
						this.getInteriorTopStyle();
					}
					
					this.dataValue['height'] = this.storageSizes.size['height'];
					this.dataValue['width'] = this.storageSizes.size['width'];
					
					if (this.serverSizeData) {
						this.valueHeight = +this.storageSizes.size['height'];
						this.valueWidth = +this.storageSizes.size['width'];
					}
				}
			}
		}
	}
	
	getStorageMaterialType(): void {
		const sizeStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data);
		
		if (sizeStorage) {
			for (const item of sizeStorage) {
				if (item.setup && item.setup.material) {
					this.storageMaterialType = item.setup.material.type;
				}
				if (item.setup && item.setup.operation) {
					this.operationName = item.setup.operation.name;
				}
			}
		}
	}
	
	updateSetting(type, event): void {
		if (type === 'width') {
			this.modelSize = event;
		}
		
		if (type === 'height') {
			this.modelSizeVert = event;
		}
		
		this.saveSessionBlind.PutStorageDataToServer();
	}
	
	onInputChange(type, event: any): void {
		const modelSize = this.sizeToPointConverter(event);
		const max = this.sizeToPointConverter(this.serverSizeData[type]?.maximum);
		const min = this.sizeToPointConverter(this.serverSizeData[type]?.minumum);
		
		this.hasExtraSizeWidth = !!this.hasExtraSizeWidth;
		this.hasExtraSizeHeight = !!this.hasExtraSizeHeight;
		
		this.sizeValue[type] = event;
		
		if (type === 'width') {
			this.hasExtraSizeWidth = +this.sizeValue.width < +this.serverSizeData.width.minumum ||
				+this.sizeValue.width > +this.serverSizeData.width.maximum;
		}
		if (type === 'height') {
			this.hasExtraSizeHeight = this.sizeValue.height < this.serverSizeData.height.minumum ||
				+this.sizeValue.height > +this.serverSizeData.height.maximum;
		}
		
		this.engineService.setSize({
				[type]: modelSize > max ? max : modelSize <= min ? min : modelSize
			}
		);
		
		this.storageSizes.size = {
			...this.storageSizes.size, ...{
				[type]: event,
				extra_size: this.hasExtraSizeWidth || this.hasExtraSizeHeight
			}
		};
		
		if (this.blindType === 'interior') {
			this.getInteriorTopStyle();
		}
		
		this.dataValue[type] = this.storageSizes.size[type];
		
		this.sizeData.emit(this.storageSizes);
		
		if (this.isDesignType) {
			this.shareDataService.setBlindSizeChanged({ id: this.blindId });
		}
		
		this.saveBlindSetup();
	}
	
	onInputChangeHandler(type, event): void {
		const modelSize = this.sizeToPointConverter(event);
		const max = this.sizeToPointConverter(this.serverSizeData[type]?.maximum);
		const min = this.sizeToPointConverter(this.serverSizeData[type]?.minumum);
		const notStartFromZeroRegExp: RegExp = /^[1-9][0-9]*$/;
		const hasExtraSize = () => !notStartFromZeroRegExp.test(event) ||
			+event < +this.serverSizeData[type].minumum ||
			+event > +this.serverSizeData[type].maximum;
		
		this.hasExtraSizeWidth = !!this.hasExtraSizeWidth;
		this.hasExtraSizeHeight = !!this.hasExtraSizeHeight;
		
		if (type === 'width') {
			this.hasExtraSizeWidth = hasExtraSize();
		}
		
		if (type === 'height') {
			this.hasExtraSizeHeight = hasExtraSize();
		}
		
		if (Math.round(event).toString().length) {
			this.setModelSizes(type, +event);
			
			if (+this.storageSizes.size[type] !== +event) {
				this.storageSizes.size = {
					...this.storageSizes.size, ...{
						[type]: event,
						extra_size: this.hasExtraSizeWidth || this.hasExtraSizeHeight
					}
				};
				
				this.dataValue[type] = this.storageSizes.size[type];
				
				if (this.blindType === 'interior') {
					this.getInteriorTopStyle();
				}
				
				this.sizeData.emit(this.storageSizes);
				this.saveBlindSetup();
				this.saveSessionBlind.PutStorageDataToServer();
			}
		}
		
		this.engineService.setSize({
				[type]: modelSize > max ? max : modelSize <= min ? min : modelSize
			}
		);
		
		this.shareDataService.setBlindSizeChanged({ id: this.blindId });
	}
	
	setBlindSelectId(data): void {
		this.blindType = this.sessionStorageService.getBlindItemById(data?.id, STORAGE_NAMES.zip_blind_data)[0]?.type || this.blindType;
		this.getStorageMaterialType();
		this.getServerDefaultData();
		this.getStorageSizes();
		
		if (this.setServerData && typeof data !== 'string' && data !== undefined) {
			if (data.status === 'new') {
				this.storageSizes.size = {
					width: this.serverSizeData.width.default,
					height: this.serverSizeData.height.default
				};
			}
			
			if (data.id === this.blindId || data.status === 'new') {
				if (_.isEmpty(this.defaultSizeData)) {
					for (const key of Object.keys(this.serverSizeData)) {
						this.setModelSizes(key, this.serverSizeData[key].default);
					}
				}
				
				for (const [key, value] of Object.entries(this.storageSizes.size)) {
					this.setModelSizes(key, value);
					this.onInputChangeHandler(key, value);
					this.dataValue[key] = value;
				}
			}
			
			if (data.id === this.blindId && this.isDesignType) {
				this.sessionStorageService.setBlindData(this.blindId, STORAGE_NAMES.zip_current_blind_id);
			}
		}
		
		this.changeDetection.markForCheck();
	}
	
	setMaterialTypeSize(type): void {
		this.currentStorageMaterialType = this.storageMaterialType || type;
		this.storageMaterialType = type;
		this.getServerDefaultData();
		this.setDefaultSizeValues();
		this.changeDetection.markForCheck();
	}
	
	setModelSizes(key, value): void {
		const sizeInPoints = this.sizeToPointConverter(value);
		
		if (key === 'width') {
			this.modelSize = value;
			this.valueWidth = value;
		}
		
		if (key === 'height') {
			this.modelSizeVert = value;
			this.valueHeight = value;
		}
		
		this.engineService.setSize({ [key]: sizeInPoints });
	}
	
	setDefaultSizeValues(): void {
		if (this.setServerData && _.isEmpty(this.storageSizes.size) && this.serverSizeData) {
			for (const key of Object.keys(this.serverSizeData)) {
				this.setModelSizes(key, +this.serverSizeData[key].default);
				this.dataValue[key] = +this.serverSizeData[key].default;
			}
			
			this.setDefaultSizeToStorage();
		}
		
		if (this.currentStorageMaterialType !== this.storageMaterialType && this.serverSizeData) {
			this.hasExtraSizeWidth = (this.storageSizes.size['width'] < +this.serverSizeData.width.minumum ||
				this.storageSizes.size['width'] > +this.serverSizeData.width.maximum);
			this.hasExtraSizeHeight = (this.storageSizes.size['height'] < +this.serverSizeData.height.minumum ||
				this.storageSizes.size['height'] > +this.serverSizeData.height.maximum);
			
			if (this.sizeValue.width) {
				this.valueWidth = this.sizeValue.width;
			}
			
			if (this.sizeValue.height) {
				this.valueHeight = this.sizeValue.height;
			}
		}
	}
	
	setDefaultSizeToStorage(): void {
		this.storageSizes.size = this.dataValue;
		this.sizeData.emit(this.storageSizes);
		this.saveBlindSetup();
		this.saveSessionBlind.PutStorageDataToServer();
	}
	
	getServerDefaultData(): void {
		this.blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
		this.setCorrectSizesHandler(this.sessionConfig[this.blindType].material);
		this.setServerData = true;
		
		this.engineService.setDefaultModelSizes({
			height: this.sizeToPointConverter(this.sessionConfig[this.blindType].material[0].sizes['height'].default),
			width: this.sizeToPointConverter(this.sessionConfig[this.blindType].material[0].sizes['width'].default)
		});
	}
	
	openSizeItem(): void {
		const title = $('app-size-change').parent(SELECTORS.config_item_content).prev(SELECTORS.config_item_title);
		
		if (!title.parent().hasClass('active') && !this.firstOpenSizeError) {
			title.click();
			this.firstOpenSizeError = true;
		}
	}
	
	inputValidate(event: KeyboardEvent) {
		const numberRegex: RegExp = /^\d+$/;
		
		if (!numberRegex.test(event.key)) {
			event.preventDefault();
		}
	}
	
	getInteriorTopStyle(): void {
		const setupData = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0].setup;
		const width = +this.storageSizes.size['width'];
		const height = +this.storageSizes.size['height'];
		const topStyleId = INTERIOR_FRAME_TOP_STYLE.reduce((acc, x) => !acc && width > x.width && height > x.height ? x.id : acc, '');
		const topStyle = this.sessionConfig[this.blindType].frame.top_style.filter(el => el.id === topStyleId)[0];
		
		setupData.frames.top_style = topStyle;
		
		this.saveBlindSetup(setupData, false);
	}
	
	saveBlindSetup(data: any = this.storageSizes, status: boolean = true): void {
		this.isDesignType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
		const storage = this.isDesignType ? STORAGE_NAMES.zip_blind_data : STORAGE_NAMES.zip_blind_data_iv;
		
		this.sessionStorageService.setBlindSetupDataById(this.blindId, data, storage);
	}
	
	sizeToPointConverter(size: string | number): number {
		return +size / 1000;
	}
}
