import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SELECTORS, VIEW_TYPES } from '@root/app.config';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';

declare var $: any;

@Component({
	selector: 'app-quiz-modal',
	templateUrl: './quiz-modal.component.html',
	styleUrls: ['./quiz-modal.component.scss', './quiz-modal.responsive.scss']
})
export class QuizModalComponent implements OnInit, AfterViewInit, OnDestroy {
	getNavigationHandler: Subscription;
	getViewChangeHandler: Subscription;
	
	isMobile: boolean;
	
	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		infinite: false,
		swipe: false,
		arrows: false,
		autoplay: false,
		adaptiveHeight: true,
		customPaging: () => {
			return '<div class="slick-dot-custom"></div>';
		},
	};
	
	quizIconsFolder: string = '/assets/icons/quiz/';
	sendSVG: SafeResourceUrl;
	slidesQuantity: number;
	currentSlideIndex: number;
	
	constructor(
		private matDialogRef: MatDialogRef<QuizModalComponent>,
		private setupPageService: SetupPageService,
		private changeDetection: ChangeDetectorRef,
		private shareDataService: ShareService,
		private router: Router,
		protected sanitizer: DomSanitizer
	) {
	}
	
	ngOnInit(): void {
		this.isMobile = this.setupPageService.mobileAndTabletCheck();
		this.sendSVG = this.sanitizer.bypassSecurityTrustResourceUrl(this.quizIconsFolder + 'send.svg');
		this.quizCloseHandler();
		this.quizStatusHandler();
	}
	
	ngAfterViewInit(): void {
		this.changeDetection.detectChanges();
	}
	
	ngOnDestroy(): void {
		this.getNavigationHandler.unsubscribe();
		this.getViewChangeHandler.unsubscribe();
	}
	
	closeDialog(): void {
		this.matDialogRef.close();
	}
	
	carouselHandler(event: any): void {
		const slick = event.slick;
		const isColumnMode = $(slick.$slideTrack).children().css('flex-direction') === 'column';
		
		if (event.event.type === 'init' && isColumnMode) {
			slick.$slider.find('img').first().on('load', () => {
				const slide = slick.$slides[0];
				const height = $(slide).find('img').height();
				$(slick.$list).height($(slide).children().first().height() + height);
			});
		}
		
		this.slidesQuantity = slick.slideCount;
		this.currentSlideIndex = slick.currentSlide;
	}

	
	quizCloseHandler(): void {
		this.getNavigationHandler = this.router.events.subscribe(event => this.closeDialog());
		this.getViewChangeHandler = this.shareDataService.getViewType
			.subscribe(res => res !== VIEW_TYPES.design || this.closeDialog());
	}
	
	quizStatusHandler(): void {
		this.matDialogRef.afterOpened().subscribe(this.switchQuizStatus.bind(this, true));
		this.matDialogRef.afterClosed().subscribe(this.switchQuizStatus.bind(this, false));
	}
	
	switchQuizStatus(status: boolean): void {
		this.shareDataService.setQuizStatus(status);
		this.setUntouchableOverlay(status);
	}
	
	setUntouchableOverlay(status: boolean): void {
		const overlay = document.querySelector(SELECTORS.overlay_container);
		
		overlay.classList.toggle('untouchable', status);
	}
}
