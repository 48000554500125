import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { AppRoutingModule } from '../app-routing.module';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { ToTopBtnComponent } from './to-top-btn/to-top-btn.component';
import { LeaveModalComponent } from './modals/leave-modal/leave-modal.component';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { RetailerSuccessModalComponent } from './modals/retailer-success-modal/retailer-success-modal.component';
import { RetailerNoSelectModalComponent } from './modals/retailer-no-select-modal/retailer-no-select-modal.component';
import { RetailerSelectModalComponent } from './modals/retailer-select-modal/retailer-select-modal.component';
import { SaveWorkModalComponent } from './modals/save-work-modal/save-work-modal.component';
import { OverviewModalComponent } from './modals/overview-modal/overview-modal.component';
import { SelectBackgroundModalComponent } from './modals/select-background-modal/select-background-modal.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReactiveFormsModule } from '@angular/forms';
import { RotateDeviceModalComponent } from './modals/rotate-device-modal/rotate-device-modal.component';
import { ArLoadingModalComponent } from './modals/ar-loading-modal/ar-loading-modal.component';
import { QuizModalComponent } from './modals/quiz-modal/quiz-modal.component';
import { RetailerInfoModalComponent } from './modals/retailer-info-modal/retailer-info-modal.component';
import { RetailerInfoPopoverComponent } from './modals/retailer-info-popover/retailer-info-popover.component';
import { AugmentedRealityComponent } from '@shared/augmented-reality/augmented-reality.component';
import { SaveWorkEmailModalComponent } from '@shared/modals/save-work-email-modal/save-work-email-modal.component';
import { DisabledLinkModalComponent } from './modals/disabled-link-modal/disabled-link-modal.component';

@NgModule({
	declarations: [
		HeaderComponent,
		LoaderComponent,
		ErrorModalComponent,
		ToTopBtnComponent,
		LeaveModalComponent,
		DeleteModalComponent,
		RetailerSuccessModalComponent,
		RetailerNoSelectModalComponent,
		RetailerSelectModalComponent,
		SelectBackgroundModalComponent,
		SaveWorkModalComponent,
		OverviewModalComponent,
		RotateDeviceModalComponent,
		ArLoadingModalComponent,
		QuizModalComponent,
		RetailerInfoModalComponent,
		RetailerInfoPopoverComponent,
		AugmentedRealityComponent,
		SaveWorkEmailModalComponent,
		DisabledLinkModalComponent
	],
	imports: [
		CommonModule,
		AppRoutingModule,
		TranslateModule,
		SlickCarouselModule,
		ReactiveFormsModule
	],
	exports: [
		HeaderComponent,
		LoaderComponent,
		ToTopBtnComponent,
		RetailerInfoPopoverComponent,
		AugmentedRealityComponent
	]
})
export class SharedModule {
}
