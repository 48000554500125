import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EngineService } from '@core/services/engine/engine.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { STORAGE_NAMES, SELECTORS } from '@root/app.config';
import { ModalService } from '@core/services/modal/modal.service';

declare var $: any;

@Component({
	selector: 'app-country-modal',
	templateUrl: './blind-type-modal.component.html',
	styleUrls: ['./blind-type-modal.component.scss', './blind-type-modal.responsive.scss']
})
export class BlindTypeModalComponent implements OnInit {
	@ViewChild('blindTypeContent') blindTypeContent: ElementRef;
	
	clicked: boolean = false;
	
	constructor(
		public shareDataService: ShareService,
		public dialogRef: MatDialogRef<BlindTypeModalComponent>,
		private engineService: EngineService,
		private sessionStorageService: SessionStorageService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
	}
	
	ngOnInit() {
		if (!this.data) {
			this.modalService.liftUpModal(this.dialogRef);
		}
	}
	
	onBlindTypeHandler(type): void {
		if (type === 'interior') {
			this.engineService.createScene('interior');
		}
		
		if (type === 'outdoor') {
			this.engineService.createScene('outdoor');
		}
		
		this.clicked = true;
		this.sessionStorageService.setSession(type, STORAGE_NAMES.zip_blind_type);
		this.shareDataService.setBlindType(type);
		this.dialogRef.close();
	}
	
	onClose(): void {
		this.dialogRef.close();
	}
}
